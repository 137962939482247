.hypnotic-2 {
  width: 120px;
  height: 50px;
  border: #05192d 4px solid;
  border-radius: 50%;
  display: grid;
  animation: h2 2s infinite linear;
  opacity: 1;
  filter: drop-shadow(2px 2px 2px #05192d);
}
.hypnotic-2:before,
.hypnotic-2:after {
  content:"";
  grid-area: 1/1;
  border:inherit;
  border-radius: 50%;
  animation: inherit;
  animation-duration: 3s;
}
.hypnotic-2:after {
  --s:-1;
}

@keyframes h2 {
   100% {transform:rotate(calc(var(--s,1)*1turn))}
}

.hyp-cont {
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
  grid-auto-rows: 40vh;
  place-items:center;
}