@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #4699eb;
  --primary-content: #05192d;
  --primary-dark: #1980e5;
  --primary-light: #74b2f0;

  --secondary: #eb9846;
  --secondary-content: #2d1905;
  --secondary-dark: #e57e19;
  --secondary-light: #f0b274;

  --background: #eaf0f5;
  --background-filter: rgb(234, 240, 245, 0.5);
  --foreground: #fafbfd;
  --border: #d4dfea;

  --copy: #192634;
  --copy-light: #42668a;
  --copy-lighter: #648cb4;

  --success: #46eb46;
  --warning: #ebeb46;
  --error: #eb4646;
  --success-content: #052d05;
  --warning-content: #2d2d05;
  --error-content: #ffffff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #ffffff; */
  /* background-color: #e1e1e1; */
  background-image: url("./images/104.avif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: var(--copy) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

span {
  color: var(--copy) !important;
}

a {
  color: var(--copy) !important;
}

.legend {
  padding: 0;
  background-color: var(--primary-content);
  border: #d4dfea solid 1px;
  border-radius: 10px;
  color: var(--error-content);
}

.legend > div > span {
  color: var(--error-content) !important;
}
.comment-card {
  /* width: 300px; */
  /* min-height: 300px; */
  /* background-color: #fff; */
  /* background-color: rgba(255, 255, 255, 0.5); */
  background-color: var(--foreground);
  color: var(--copy) !important;
  /* backdrop-filter: blur(10px); */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  border-radius: 15px;
}

.comment-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #404040;
}

.border-bottom-radius {
  border-bottom: 1px solid var(--border);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.comment-body {
  padding: 15px;
}

.toggle-icon {
  font-size: 1.5rem;
  font-weight: bold;
}

.comment-header {
  position: relative;
  display: flex;
  align-items: center;
}

.avatar-icon {
  margin-right: 15px;
  font-size: xx-large;
  padding: 10px;
  border-radius: 50%;
}

.header-padding {
  padding-top: 20px;
}

/* .comment-body {
  min-height: 250px;
} */
/* --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px; */

@media (min-width: 1400px) {
  .comment-body {
    min-height: 170px;
  }
}

@media (min-width: 1200px) {
  .comment-body {
    min-height: 200px;
  }
}

@media (max-width: 992px) {
  .comment-header {
    min-height: 150px;
  }
}

.comment-body span {
  color: #007bff;
  font-size: smaller;
}

.comment-body span:hover {
  text-decoration: underline;
  cursor: pointer;
}

.comment-date {
  color: #404040;
  font-size: smaller;
}

@media screen and (min-width: 1094px) and (max-width: 1357px) {
  .layout {
    margin-top: 100px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1093px) {
  .layout {
    margin-top: 110px !important;
  }
}

.layout {
  margin-top: 70px;
  max-width: 100% !important;
}

.content-background {
  background-color: var(--background-filter);
  backdrop-filter: blur(10px);
  border: 1px solid #d4dfea;
  border: 1px solid #d4dfea;
  border-radius: 15px;
  padding: 10px;
}

.layout-header {
  text-align: center;
  margin-bottom: 20px;
}

.man-from-the-house {
  color: #0000ff;
  font-style: italic;
  font-size: large;
}

.header-style {
  font-family: "Arial", sans-serif;
  letter-spacing: 0.15em;
}

.notice-me {
  font-weight: 900;
  color: var(--primary-content) !important;
}

.comment-button {
  text-align: right;
  padding-right: 30px !important;
}

.comment-button-element {
  background-color: var(--primary-content) !important;
  border-color: var(--primary-content) !important;
}

.glass-navbar {
  width: 100%;
  z-index: 9999;
  /* color: white; */
  /* background-color: #fefefe13 !important; */
  background-color: var(--background-filter);
  backdrop-filter: blur(10px);
  border: 1px solid #fefefe2e;
  border-radius: 15px;
  box-shadow: 0 10px 35px 0 #362100a6;
  padding-left: 10px !important;
  padding-right: 10px !important;
  max-height: 100vh;
  /* overflow-y: scroll; */
}

@media (max-width: 992px) {
  .glass-navbar {
    overflow-y: scroll;
  }
}

.form-group-padding {
  padding: 10px;
}

.centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .click-click-go:hover > .click-click-go-child {
  transform:scaleY(1.1);
  border-radius: 15px;
  background-color: #007bff !important;
} */

/* @keyframes backgroundColorPalette {
  0% { background: #87CEEB; }
  100% { background: #007bff; }
} */

/* .click-click-go-child {
  animation-name: backgroundColorPalette;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-radius: 15px;
} */

.click-click-go {
  cursor: pointer;
}

.click-click-go:hover .click-click-go-child {
  scale: 1.05;
}

.click-click-go-child {
  background-color: var(--primary) !important;
  z-index: 9999999;
  transition: scale 0.5s;
}

@media (max-width: 576px) {
  .click-click-go-child {
    animation: scale-loop 3s infinite;
  }
}

@keyframes scale-loop {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.continue {
  margin-top: 50%;
  margin-left: 5%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.click-click-go:hover .continue {
  opacity: 1;
  visibility: visible;
}

.grandparents {
  background-color: #4699eb;
}

.oldest {
  background-color: #eb9846;
}

.middle {
  background-color: #f0b274;
}

.youngest {
  background-color: #4699eb;
}

.not-found {
  text-align: center;
  padding: 50px;
  background-color: rgb(248, 249, 250, 0.5);
  border-radius: 10px;
}

.not-found h1 {
  font-size: 80px;
  color: #6c757d;
}

.not-found p {
  font-size: 24px;
  color: #6c757d;
}

.not-found a {
  text-decoration: none;
}

.dropdown-menu{
  background-color: #90c0e7;
  text-align: center;
  /* backdrop-filter: blur(50px); */
}

.dropdown-item:hover{
  background-color: transparent;  
  color: #05192d;
  text-shadow: 0 0 2px #05192d;
  /* transform: scale(1.1); */
  transition: all 0.25s ease-in-out;
}

.dropdown-item:focus{
  background-color: transparent;  
  color: #05192d;
  text-shadow: 0 0 2px #05192d;
  /* transform: scale(1.1); */
  transition: all 0.25s ease-in-out;
}

/* .dropdown-item:nth-child(1):hover{
  background-color: transparent;
  color: #05192d;
  text-shadow: 0 0 2px #05192d;
  transform: scale(1.1) translateX(10px);
  transition: all 0.25s ease-in-out;
} */

/* .dropdown-item span:hover{
  background-color: #90c0e7;
  color: #05192d;
  text-shadow: 0 0 2px #05192d;
  transform: scale(1.1) !important;
  transition: all 0.25s ease-in-out;
} */

.nav-link:hover{
  filter: drop-shadow(1px 1px 1px var(--copy));
  /* margin-left: calc(var(--bs-navbar-nav-link-padding-x) * 1.1); */
  /* transform: scale(1.1); */
  transition: all 0.25s ease-in-out;
}

.nav-link:focus{
  filter: drop-shadow(1px 1px 1px var(--copy));
  /* margin-left: calc(var(--bs-navbar-nav-link-padding-x) * 1.1); */
  /* transform: scale(1.1); */
  transition: all 0.25s ease-in-out;
}

.active-link {
  filter: drop-shadow(1px 1px 1px var(--copy));
  /* margin-left: calc(var(--bs-navbar-nav-link-padding-x) * 1.1); */
  /* transform: scale(1.1); */
  transition: all 0.25s ease-in-out;
}
